html,
body {
  padding: 0;
  margin: 0;
  background: #F9FBFF;
  color: #283c63;
}

body, main, #root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
ul,
dt,
dd {
  margin: 0;
  color: #283c63;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0.5rem 1rem;
  font-weight: bold;
}

.card {
  border-radius: 1px;
}

/* Footer */
footer {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  bottom: 0;
  position: fixed;
  background-color: #F9FBFF;
}

/* Bootstrap  */

:root {
  --primary-color: #283c63; /* Your custom blue color */
  --custom-background-color: #283c63; /* Your custom background color */
 /*  --custom-link-color: #283c63; Your custom link color */
}

.custom-primary {
  color: var(--primary-color);
}

.navbar {
  height: 80px;
}

.widget-image {
  width: 300px;
}

.main-image {
  width: 600px;
}

.pl-3 {
  padding-left: 20px;
}

h2 {
  font-size: 1.5rem;
}

.figure-image {
  height: 600px;
}

.figure-large {
  height: 400px;
}